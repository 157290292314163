<template>
  <div>
    <nav-top />
    <div class="container-fluid">
      <div class="row flex-xl-nowrap">
        <div
          :class="{ 'bd-banner-sidebar': isBannerEnabled }"
          class="col-12 col-md-3 col-xl-2 bd-sidebar"
        >
          <nav-left />
          <pre class="small d-none d-md-block custom-other">
 v{{ version }} </pre
          >
          <span class="small d-none d-md-block custom-other custom-role">{{
            userRole
          }}</span>
        </div>
        <main
          class="col-12 col-md-9 col-xl-10 py-3 px-md-5 bd-content"
          role="main"
        >
          <slot />
          <div class="d-md-none text-muted">
            <hr />
            <pre class="small"> v{{ version }} </pre>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { authStore } from '@/stores/auth'
import NavTop from '@/layouts/navigation/NavTop.vue'
import NavLeft from '@/layouts/navigation/NavLeft.vue'

export default {
  name: 'LayoutSideNav',
  components: {
    NavTop,
    NavLeft,
  },
  computed: {
    ...mapState(authStore, ['profile']),
    version() {
      let _version = import.meta.env.VITE_VERSION
      return _version
    },
    isBannerEnabled() {
      let env = window.config ? window.config.environment : ''
      return env !== 'prod'
    },
    userRole() {
      let _role = 'Admin'
      if (this.profile) {
        if (this.profile.roles) {
          if (this.profile.roles.length > 0) {
            switch (this.profile.roles[0]) {
              case 3:
                _role = 'Franchiser (admin)'
                break
              case 7:
                _role = 'Franchiser (user)'
                break
              case 8:
                _role = 'Franchisee (admin)'
                break
              case 9:
                _role = 'Franchisee (user)'
                break
              case 4:
                _role = 'Shop (admin)'
                break
              case 5:
                _role = 'Shop (user)'
                break
            }
          }
        }
      }
      return _role
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-other {
  color: #8a8a8a !important;
}
.custom-role {
  margin-left: 5px;
}
</style>
