<template>
  <z-tabs v-model="currentTab" :tabs="tabs" @change="showTab" />
  <div v-if="currentTab === 'BMS'" class="custom-tab">
    <SendBms />
  </div>
  <div v-if="currentTab === 'Device'" class="custom-tab">
    <AdasFromDevice />
  </div>
  <div v-if="currentTab === 'ADAS (CCC)'" class="custom-tab">
    <AdasQuoteCcc />
  </div>
  <div v-if="currentTab === 'ADAS (Mitchell)'" class="custom-tab">
    <AdasQuoteMitchell />
  </div>
</template>
<script>
import ZTabs from '@/components/utilities/elements/Tabs.vue'
import SendBms from '@/components/tool/send-bms.vue'
import AdasFromDevice from '@/components/tool/adas-from-device.vue'
import AdasQuoteCcc from '@/components/tool/adas-quote-ccc.vue'
import AdasQuoteMitchell from '@/components/tool/adas-quote-mitchell.vue'
export default {
  name: 'ToolsMain',
  components: {
    ZTabs,
    SendBms,
    AdasFromDevice,
    AdasQuoteCcc,
    AdasQuoteMitchell
  },
  data() {
    return {
      currentTab: 'BMS'
    }
  },
  mounted() {
    // Add your mounted logic here
  },
  computed: {
    tabs() {
      let _tabs = ['BMS', 'Device', 'ADAS (CCC)', 'ADAS (Mitchell)']
      return _tabs
    }
  },
  methods: {
    showTab(value) {
      this.currentTab = value
    }
  }
}
</script>
<style lang="scss" scoped></style>
