<template>
  <tools />
</template>
<script>
import Tools from '@/components/tool/tools.vue'
export default {
  name: 'ToolsView',
  components: {
    Tools,
  },
}
</script>
<style lang="scss" scoped></style>
