<template>
  <div class="row mt-2">
    <div class="col">
      <p>Paste JSON request, click 'Create' to simulate the creation of an ADAS Map Inspection from the Device.</p>
    </div>
  </div>
  <div class="row">
    <div class="col custom-json">
      <ZTextArea v-model="currentScript" rows="23" maxlength="999999" placeholder="Paste JSON request here" />
    </div>
    <div class="col-1">
      <button class="btn btn-primary btn-sm" :disabled="isFetching" @click="fetchADASInspection">
        <FontAwesomeIcon v-if="isFetching" icon="fa-solid fa-spinner me-2" spin />
        Create
      </button>
    </div>
    <div class="col">
      <div v-if="isFetching">
        <FontAwesomeIcon icon="fa-solid fa-spinner me-2" spin />
        Creating ADAS Inspection...
      </div>
      <div v-else class="custom-json">
        <json-viewer :value="adasInspection" copyable />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { toolStore } from '@/stores/tool'
import { JsonViewer } from 'vue3-json-viewer'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ZTextArea from '@/components/utilities/elements/TextArea.vue'
import 'vue3-json-viewer/dist/index.css'
export default {
  name: 'AdasFromDevice',
  components: {
    FontAwesomeIcon,
    JsonViewer,
    ZTextArea
  },
  data() {
    return {
      currentScript: localStorage.getItem('adasInspection')
    }
  },
  mounted() {
    this.resetAdasInspection()
  },
  computed: {
    ...mapState(toolStore, ['loading', 'adasInspection']),
    isFetching() {
      return this.loading.adasInspection
    }
  },
  methods: {
    ...mapActions(toolStore, ['resetAdasInspection', 'createAdasInspection']),
    getContent() {
      console.log('The content is: ' + this.currentScript)
    },
    async fetchADASInspection() {
      this.resetAdasInspection()
      await this.createAdasInspection(this.currentScript)
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-json {
  height: 600px;
  overflow: auto;
  max-height: 600px;
}
</style>
