<template>
  <div class="row mt-2">
    <div class="col">
      <p>
        Instead of the normal BMS process (similar to CCC), Mitchell will be sending a request via a JSON object, which includes the repair lines.
      </p>
      <p>Paste JSON request, click 'Predictive ADAS' to get what is sent to Mitchell as an ADAS Map Predictive result.</p>
    </div>
  </div>
  <div class="row">
    <div class="col custom-json">
      <ZTextArea v-model="currentScript" rows="23" maxlength="999999" placeholder="Paste JSON request here" />
    </div>
    <div class="col-1">
      <button class="btn btn-primary btn-sm" :disabled="isFetching" @click="fetchPredictive">
        <FontAwesomeIcon v-if="isFetching" icon="fa-solid fa-spinner me-2" spin />
        Predictive ADAS
      </button>
    </div>
    <div class="col">
      <div v-if="isFetching">
        <FontAwesomeIcon icon="fa-solid fa-spinner me-2" spin />
        Fetching Predictive...
      </div>
      <div v-else class="custom-json">
        <json-viewer :value="predictive" copyable />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { toolStore } from '@/stores/tool'
import { JsonViewer } from 'vue3-json-viewer'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ZTextArea from '@/components/utilities/elements/TextArea.vue'
import 'vue3-json-viewer/dist/index.css'
export default {
  name: 'PredictiveAdasMitchell',
  components: {
    FontAwesomeIcon,
    JsonViewer,
    ZTextArea
  },
  data() {
    return {
      currentScript: localStorage.getItem('predictive')
    }
  },
  mounted() {
    this.resetPredictive()
  },
  computed: {
    ...mapState(toolStore, ['loading', 'predictive']),
    isFetching() {
      return this.loading.predictive
    }
  },
  methods: {
    ...mapActions(toolStore, ['resetPredictive', 'getPredictive']),
    getContent() {
      console.log('The content is: ' + this.currentScript)
    },
    async fetchPredictive() {
      this.resetPredictive()
      await this.getPredictive(this.currentScript)
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-json {
  height: 600px;
  overflow: auto;
  max-height: 600px;
}
</style>
