<template>
  <div class="row mt-2">
    <div class="col">
      <p>Paste BMS XML request, click 'Send' to create new ADAS Map Inspection using BMS.</p>
      <div>
        <ul class="custom-list">
          <li>for CCC, be sure you set the RepairFacility > Party > OrgInfo > IDInfo tag with your CCC Secureshare ID.</li>
          <li>
            for Mitchell, be sure you set the RepairFacility > Party > OrgInfo > CompanyName tag with your Mitchell ID at the end (after a dash),
            e.g., OPUS IVS - 10335323.
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col custom-json">
      <ZTextArea v-model="currentScript" rows="23" maxlength="999999" placeholder="Paste BMS XML request here" />
    </div>
    <div class="col-1">
      <button class="btn btn-primary btn-sm" :disabled="isSending" @click="pushBms">
        <FontAwesomeIcon v-if="isSending" icon="fa-solid fa-spinner me-2" spin />
        Send BMS
      </button>
    </div>
    <div class="col">
      <div v-if="isSending">
        <FontAwesomeIcon icon="fa-solid fa-spinner me-2" spin />
        Sending BMS...
      </div>
      <div v-else class="custom-json">
        <span v-if="isSent" class="text-success">BMS sent successfully!</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { toolStore } from '@/stores/tool'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ZTextArea from '@/components/utilities/elements/TextArea.vue'
import 'vue3-json-viewer/dist/index.css'
export default {
  name: 'SendBms',
  components: {
    FontAwesomeIcon,
    ZTextArea
  },
  data() {
    return {
      currentScript: localStorage.getItem('bms'),
      isSent: false
    }
  },
  mounted() {
    this.isSent = false
    this.resetBms()
  },
  computed: {
    ...mapState(toolStore, ['loading', 'bms']),
    isSending() {
      return this.loading.bms
    }
  },
  methods: {
    ...mapActions(toolStore, ['resetBms', 'sendBms']),
    getContent() {
      console.log('The content is: ' + this.currentScript)
    },
    async pushBms() {
      this.isSent = false
      this.resetBms()
      await this.sendBms(this.currentScript)
      this.isSent = true
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-json {
  height: 600px;
  overflow: auto;
  max-height: 600px;
}
.custom-list {
  list-style-type: none;
  padding-left: 20px;
}
</style>
