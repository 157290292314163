import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

export const toolStore = defineStore('tool', {
  state: () => ({
    loading: {
      bms: false,
      adasInspection: false,
      registration: false,
      quote: false,
      predictive: false
    },
    bms: null,
    adasInspection: null,
    registration: null,
    quote: null,
    predictive: null
  }),
  actions: {
    resetBms() {
      this.bms = null
    },
    resetAdasInspection() {
      this.adasInspection = null
    },
    resetRegister() {
      this.registration = null
    },
    resetQuote() {
      this.quote = null
    },
    resetPredictive() {
      this.predictive = null
    },
    async sendBms(data) {
      this.loading.bms = true
      this.bms = null
      localStorage.setItem('bms', data)
      let results = await api.post('admin/bms', data)
      if (results) {
        this.bms = results
      }
      this.loading.bms = false
      return this.bms
    },
    async createAdasInspection(data) {
      this.loading.adasInspection = true
      this.adasInspection = null
      localStorage.setItem('adasInspection', data)
      let results = await api.post('admin/adasInspectionDevice', data)
      if (results) {
        this.adasInspection = results
      }
      this.loading.adasInspection = false
      return this.adasInspection
    },
    async saveRegistration(data) {
      this.loading.registration = true
      this.registration = null
      localStorage.setItem('register', data)
      let results = await api.post('admin/register', data)
      if (results) {
        this.registration = results
      }
      this.loading.registration = false
      return this.registration
    },
    async getQuote(data) {
      this.loading.quote = true
      this.quote = null
      localStorage.setItem('quote', data)
      let results = await api.post('admin/quote', data)
      if (results) {
        this.quote = results
      }
      this.loading.quote = false
      return this.quote
    },
    async getPredictive(data) {
      this.loading.predictive = true
      this.predictive = null
      localStorage.setItem('predictive', data)
      let results = await api.post('admin/predictiveAdas', data)
      if (results) {
        this.predictive = results
      }
      this.loading.predictive = false
      return this.predict
    }
  }
})
