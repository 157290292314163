<template>
  <z-tabs v-model="currentTab" :tabs="tabs" @change="showTab" />
  <div v-if="currentTab === 'Register'" class="custom-tab">
    <div class="row mt-2">
      <div class="col">
        <p>The Registration must take place before you can process any Quotes.</p>
        <p>Paste Registration JSON request, click 'Register' to register a new shop location.</p>
      </div>
    </div>
    <div class="row">
      <div class="col custom-json">
        <ZTextArea v-model="currentRegisterScript" rows="23" maxlength="999999" placeholder="Paste JSON request here" />
      </div>
      <div class="col-1">
        <button class="btn btn-primary btn-sm" :disabled="isFetching" @click="register">
          <FontAwesomeIcon v-if="isFetching" icon="fa-solid fa-spinner me-2" spin />
          Register
        </button>
      </div>
      <div class="col">
        <div v-if="isFetching">
          <FontAwesomeIcon icon="fa-solid fa-spinner me-2" spin />
          Registering...
        </div>
        <div v-else class="custom-json">
          <json-viewer :value="registration" copyable />
        </div>
      </div>
    </div>
  </div>
  <div v-if="currentTab === 'Quote'" class="custom-tab">
    <div class="row mt-2">
      <div class="col">
        <p>
          It is assumed the ADAS Inspection has already been created within ADAS Map through the normal BMS process via CCC Secureshare. You can use
          the BMS tab in this toolset to push over an estimate with repair lines.
        </p>
        <p>Paste JSON request, click 'Quote' to get a CCC formatted ADAS Map quote.</p>
      </div>
    </div>
    <div class="row">
      <div class="col custom-json">
        <ZTextArea v-model="currentScript" rows="23" maxlength="999999" placeholder="Paste JSON request here" />
      </div>
      <div class="col-1">
        <button class="btn btn-primary btn-sm" :disabled="isFetching" @click="fetchQuote">
          <FontAwesomeIcon v-if="isFetching" icon="fa-solid fa-spinner me-2" spin />
          Quote
        </button>
      </div>
      <div class="col">
        <div v-if="isFetching">
          <FontAwesomeIcon icon="fa-solid fa-spinner me-2" spin />
          Fetching Quote...
        </div>
        <div v-else class="custom-json">
          <json-viewer :value="quote" copyable />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>The 'license_number' is the CCC Secureshare ID.</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { toolStore } from '@/stores/tool'
import { JsonViewer } from 'vue3-json-viewer'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ZTabs from '@/components/utilities/elements/Tabs.vue'
import ZTextArea from '@/components/utilities/elements/TextArea.vue'
import 'vue3-json-viewer/dist/index.css'
export default {
  name: 'AdasQuoteCcc',
  components: {
    FontAwesomeIcon,
    JsonViewer,
    ZTabs,
    ZTextArea
  },
  data() {
    return {
      currentTab: 'Quote',
      currentRegisterScript: localStorage.getItem('register'),
      currentScript: localStorage.getItem('quote')
    }
  },
  mounted() {
    this.resetQuote()
  },
  computed: {
    ...mapState(toolStore, ['loading', 'registration', 'quote']),
    tabs() {
      let _tabs = ['Register', 'Quote']
      return _tabs
    },
    isFetching() {
      return this.loading.quote
    }
  },
  methods: {
    ...mapActions(toolStore, ['resetRegister', 'saveRegistration', 'resetQuote', 'getQuote']),
    showTab(value) {
      this.currentTab = value
    },
    getContent() {
      console.log('The content is: ' + this.currentScript)
    },
    async register() {
      this.resetRegister()
      await this.saveRegistration(this.currentRegisterScript)
    },
    async fetchQuote() {
      this.resetQuote()
      await this.getQuote(this.currentScript)
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-json {
  height: 600px;
  overflow: auto;
  max-height: 600px;
}
</style>
